import { environment, isDevelopment } from '@Infowijs-eng/component-library/modules'
import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import Routes from './routes'

import '@Infowijs-eng/component-library/theme/infowijs.css'

if (
  !isDevelopment
  && navigator.userAgent.toLowerCase()
    .indexOf('safari') > -1
  && navigator.userAgent.toLowerCase()
    .indexOf('chrome') === -1
  && process.env.NODE_ENV === 'development'
) {
  // eslint-disable-next-line no-console
  console.error('Please use another browser to run this client in Development Mode with a Staging backend')
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://8e9bcc72548047c7bdbf62daccde4318@o97448.ingest.sentry.io/4504254114234368',
    integrations: [new BrowserTracing()],
    environment: environment(),
    release: `infowijs-dashboard-client@${process.env.VERSION}`,
    tracesSampleRate: 0.5,
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <StrictMode>
    <Routes />
  </StrictMode>,
)
