import {
  Button,
  Card,
  Clickable,
  FormField,
  Heading,
  PasswordStrengthTester,
} from '@Infowijs-eng/component-library/components'
import { Trans } from '@lingui/react'
import {
  ArrowRight,
  Eye,
} from '@Infowijs-eng/component-library/icons'
import {
  cn,
  usePasswordStrengthTester,
} from '@Infowijs-eng/component-library/modules'
import {
  useState,
} from 'react'
import PropTypes from 'prop-types'
import {
  useParams,
} from 'react-router-dom'
import generatePassword from '../../modules/generatePassword'
import useStoreOneTimeCredentials from '../../modules/requests/useStoreOneTimeCredentials'
import LoadingIndicator from '../LoadingIndicator'

function OneTimeCredentialsMagister({
  onSuccess, onFailure, importerData, loading,
}) {
  const { importerId, securityToken } = useParams()
  const [storeOneTimeCredentials] = useStoreOneTimeCredentials(importerId, securityToken)
  const [portal, setPortal] = useState('')
  const [password, setPassword] = useState('')
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [passwordStrength, testPasswordStrength] = usePasswordStrengthTester()
  const [copied, setCopied] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [showCopyButton, setShowCopyButton] = useState(false)

  if (loading) {
    return (
      <div>Loading...</div>
    )
  }

  return (
    <Card className="max-w-3xl p-8">
      <div className="p-4 space-y-4">
        <Heading tag={Heading.size.H1} fontSize={Heading.size.H3}>
          <Trans id="OneTimeCredentialsMagister.title" />
        </Heading>
        <p className="leading-loose">
          <Trans
            id="OneTimeCredentialsMagister.introduction"
            values={{ customerName: importerData.customerTitle || '' }}
          />
        </p>
        <p className="leading-loose">
          <Trans
            id="OneTimeCredentialsMagister.introduction2"
            components={{
              /* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label */
              0: <a
                role="button"
                className="text-primary-700"
                target="_blank"
                href="https://hoy.app/magister"
                rel="noreferrer"
              />,
            }}
          />
        </p>

        <FormField
          name="portal"
          label={<Trans id="OneTimeCredentialsMagister.portal.fieldLabel" />}
          type="text"
          required
          value={portal}
          onChange={(event) => {
            setPortal(event.target.value)
          }}
          inputProps={{
            trailingAddon: (
              <div className="text-gray-400 font-medium text-base">
                <Trans id="OneTimeCredentialsMagister.portal.trailingAddon" />
              </div>
            ),
          }}
        />

        <FormField
          name="username"
          label={<Trans id="OneTimeCredentialsMagister.username.fieldLabel" />}
          type="text"
          disabled
          value="infowijs"
        />

        <FormField
          name="password"
          label={<Trans id="OneTimeCredentialsMagister.password.fieldLabel" />}
          type={passwordVisibility ? 'text' : 'password'}
          value={password}
          required
          onChange={(event) => {
            testPasswordStrength(event.target.value)
            setPassword(event.target.value)
          }}
          inputProps={{
            trailingAddon: (
              <div className="flex flex-row items-center space-x-6">
                <Clickable
                  onClick={() => {
                    setPasswordVisibility(!passwordVisibility)
                  }}
                >
                  <Eye className="text-lg" />
                </Clickable>
                <PasswordStrengthTester value={passwordStrength} />
              </div>
            ),
          }}
        />
        <div className="font-medium italic text-sm leading-normal space-x-2">
          {!passwordStrength && (
            <Trans id="OneTimeCredentialsMagister.password.defaultHint" />
          )}
          {passwordStrength && passwordStrength < 3 && (
            <div>
              <div><Trans id="OneTimeCredentialsMagister.password.hintWeakPassword" /></div>
              <Clickable
                className="text-primary-700 italic text-sm font-medium"
                onClick={() => {
                  const generatedPassword = generatePassword(36)
                  setPassword(generatedPassword)
                  setShowCopyButton(true)
                  testPasswordStrength(generatedPassword)
                }}
              >
                <Trans id="OneTimeCredentialsMagister.password.hintPasswordSuggestion" />
              </Clickable>
            </div>
          )}
          {showCopyButton && passwordStrength >= 3 && (
            <>
              <Clickable
                className="text-primary-700 italic text-sm font-medium pr-2"
                onClick={() => {
                  navigator.clipboard.writeText(password).then(() => {
                    setCopied(true)
                    setTimeout(() => {
                      setCopied(false)
                    }, 2000)
                  })
                }}
              >
                <Trans id="OneTimeCredentialsMagister.password.hintCopyToClipboard" />
              </Clickable>
              <span className={cn('text-green-700 transition-opacity opacity-0', copied && 'opacity-100')}>
                <Trans id="OneTimeCredentialsMagister.password.hintCopiedToClipboard" />
              </span>
            </>
          )}
        </div>

        <div className="justify-end flex">
          <Button
            primary
            disabled={portal.length < 2 || passwordStrength < 3 || submitting}
            trailingAddon={submitting ? <LoadingIndicator /> : <ArrowRight />}
            onClick={() => {
              setSubmitting(true)
              storeOneTimeCredentials({
                data: {
                  portal,
                  password,
                },
              })
                .then(() => {
                  setSubmitting(false)
                  onSuccess()
                })
                .catch((apiException) => {
                  setSubmitting(false)
                  onFailure(apiException.errors)
                })
            }}
          >
            <Trans id="OneTimeCredentialsMagister.complete.label" />
          </Button>
        </div>
      </div>
    </Card>
  )
}

OneTimeCredentialsMagister.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  importerData: PropTypes.shape({
    customerTitle: PropTypes.string,
  }),
  loading: PropTypes.bool,
}

OneTimeCredentialsMagister.defaultProps = {
  loading: false,
  importerData: {},
}

export default OneTimeCredentialsMagister
